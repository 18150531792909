<template>
  <div>
    <van-steps :active="active" direction="vertical" active-color="#c12b35">
      <van-step>开始</van-step>
      <van-step>
        <strong>绑定新生</strong>
        <p>注册并登录广西外国语学院新生报到系统，填写姓名、身份证号等信息</p>
      </van-step>
      <van-step>
        <strong>学费缴纳</strong>
        <p>
          使用微信在线缴纳学费，经济困难学生可申请绿色通道缴纳，并支持分批次缴纳
        </p>
      </van-step>
      <van-step>
        <strong>选择宿舍</strong>
        <p>根据你的喜好选择宿舍，并缴纳住宿费</p>
      </van-step>
      <van-step>
        <strong>来校登记</strong>
        <p>登记来校时间、到站站点、人数等信息</p>
      </van-step>
      <van-step>完成</van-step>
    </van-steps>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: -1,
    };
  },
  created() {},
  methods: {},
};
</script>